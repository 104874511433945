import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import Swal from "sweetalert2";
import { apiCall, tysApiCall } from "../../../utils/util";

import {
  GET_USERS_SERVICE_REQUEST,
  GET_USER_INFO_SERVICE_REQUEST,
  POST_LOGIN_SERVICE_REQUEST,
} from "../../actions";
import {
  getUserInfoServiceFailed,
  getUserInfoServiceSucceed,
  getUsersServiceFailed,
  getUsersServiceSucceed,
  postLoginServiceFailed,
  postLoginServiceSucceed,
} from "../userService";

function* watchGetUsersService() {
  yield takeLatest(GET_USERS_SERVICE_REQUEST, getUsers);
}

function* getUsers() {
  try {
    const res = yield call(apiCall, "get", "user", {});
    yield put(
      getUsersServiceSucceed({
        data: res.data,
      })
    );
  } catch (err) {
    yield put(getUsersServiceFailed());
  }
}

function* watchPostLoginService() {
  yield takeLatest(POST_LOGIN_SERVICE_REQUEST, postLogin);
}

function* postLogin(action) {
  const { email, password, navigate } = action.payload;

  try {
    const res = yield call(
      tysApiCall,
      "post",
      "login",
      { email: action.payload.email, password: action.payload.password },
      {
        "Content-Type": "application/json",
      }
    );

    yield localStorage.setItem("token", res.data.authorizationTicket);
    yield localStorage.setItem("email", email);

    yield put(
      postLoginServiceSucceed({
        data: res.data,
      })
    );
    if (res.data.success) {
      navigate("/home");
    } else {
      Swal.fire("Hata", "Kullanıcı Adı veya şifre hatalı", "error");
    }
  } catch (err) {
    yield put(postLoginServiceFailed());
  }
}

function* watchGetUserInfoService() {
  yield takeLatest(GET_USER_INFO_SERVICE_REQUEST, getUserInfo);
}

function* getUserInfo(action) {
  const navigate = action.payload;
  const kullaniciAdi = localStorage.getItem("email");
  const token = localStorage.getItem("token");

  try {
    const res = yield call(tysApiCall, "post", "findKullaniciDetay", {
      kullaniciAdi: kullaniciAdi,
    });

    if (res.data.resultCode == 401) {
      yield navigate("/signin");
    }

    let data = res.data;
    if (res.data.tysKullaniciBilgileriDto !== null) {
      data = { ...data, isAdmin: false };
    } else {
      data = { ...data, isAdmin: true };
    }

    yield put(
      getUserInfoServiceSucceed({
        data: data,
      })
    );
  } catch (err) {
    yield put(getUserInfoServiceFailed());
  }
}

export default function* userSaga() {
  yield all([
    fork(watchGetUsersService),
    fork(watchPostLoginService),
    fork(watchGetUserInfoService),
  ]);
}
