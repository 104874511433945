import * as actions from "../../actions";

const initialState = {
  companies: [],
};

const company = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_COMPANIES_SERVICE_REQUEST:
      return {
        ...state,
      };
    case actions.GET_COMPANIES_SERVICE_SUCCEED:
      return {
        ...state,
        companies: action.payload.data,
      };
    case actions.GET_COMPANIES_SERVICE_FAILED:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default company;
