import { Container, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getCompaniesServiceRequest } from "../../../services/company/companyService";
import {
  getAllSpesificationsServiceRequest,
  getSpesificationsServiceRequest,
} from "../../../services/spesification/spesificationService";
import { getUserInfoServiceRequest } from "../../../services/user/userService";
import { aggregateBcDataAndTysData, trimBcData } from "../../../utils/util";
import Orders from "./Orders";
const Tubu = require("@tubu/tubuio-sdk-node");

function Copyright(props) {
  return (
    <Typography variant="body2" align="center" {...props}>
      {"Copyright © "}
      <Link href="https://www.gazmer.com.tr/Default">GAZBİR GAZMER</Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const Homepage = () => {
  const navigate = useNavigate();

  const [bcData, setBcData] = useState(null);

  const { user } = useSelector((state) => state.user);
  const { companies } = useSelector((state) => state.company);
  const { allSpesifications, spesifications } = useSelector(
    (state) => state.spesification
  );

  const app = new Tubu("aed9548e-9130-4535-8808-05626caf786b");
  const contract = app.contract("fbf994a2b2e94c0b");

  const dispatch = useDispatch();

  useEffect(() => {
    getBlockchainData();
    dispatch(getUserInfoServiceRequest());
    dispatch(getCompaniesServiceRequest());
    dispatch(getAllSpesificationsServiceRequest());
    dispatch(getSpesificationsServiceRequest());
  }, [dispatch]);

  const getBlockchainData = () => {
    contract
      .event("CreateCertificate")
      .then((result) => {
        //filter data by status.
        let filteredData = result.data.filter((data) => {
          return data.returnValues._status === "ACTIVE";
        });
        setBcData(trimBcData(filteredData));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Container maxWidth="xxl">
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: 700,
            mb: 2,
          }}
        >
          Hoş geldiniz, {user?.sisKullaniciDto?.isim}.
        </Typography>
        <Grid container spacing={3}>
          {/* Recent Deposits */}
          <Grid item xs={12} md={4} lg={3}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: 240,
              }}
            >
              <Typography component="h2" variant="h6" gutterBottom>
                Toplam Sertifika Sayısı
              </Typography>
              <Typography component="p" variant="h4">
                {spesifications &&
                  bcData &&
                  aggregateBcDataAndTysData(bcData, spesifications)?.length}
              </Typography>
              <Typography color="text.secondary" sx={{ flex: 1 }}></Typography>
            </Paper>
          </Grid>
          {/* Recent Deposits */}
          <Grid item xs={12} md={4} lg={3}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: 240,
              }}
            >
              <Typography component="h2" variant="h6" gutterBottom>
                Ağdaki Sertifika Sayısı
              </Typography>
              <Typography component="p" variant="h4">
                {bcData?.length}
              </Typography>
              <Typography color="text.secondary" sx={{ flex: 1 }}></Typography>
            </Paper>
          </Grid>
          {/* Recent Deposits */}
          <Grid item xs={12} md={4} lg={3}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: 240,
              }}
            >
              <Typography component="h2" variant="h6" gutterBottom>
                Şirket Sayısı
              </Typography>
              <Typography component="p" variant="h4">
                {companies?.length}
              </Typography>
              <Typography color="text.secondary" sx={{ flex: 1 }}></Typography>
            </Paper>
          </Grid>
          {/* Recent Deposits */}
          <Grid item xs={12} md={4} lg={3}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: 240,
              }}
            >
              <Typography component="h2" variant="h6" gutterBottom>
                Şartname Sayısı
              </Typography>
              <Typography component="p" variant="h4">
                {allSpesifications?.length}
              </Typography>
              <Typography color="text.secondary" sx={{ flex: 1 }}></Typography>
            </Paper>
          </Grid>
          {/* Recent Orders */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              <Orders
                data={
                  bcData &&
                  spesifications &&
                  aggregateBcDataAndTysData(bcData, spesifications)
                }
              />
            </Paper>
          </Grid>
        </Grid>
        <Copyright sx={{ pt: 4 }} />
      </Container>
    </>
  );
};

export default Homepage;
