import { Container, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import GTable from "../../../components/GTable/GTable";
import { companiesConfig } from "../../../constants/columnsConfigurations";
import { getCompaniesServiceRequest } from "../../../services/company/companyService";
const Companies = () => {
  const { companies } = useSelector((state) => state.company);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompaniesServiceRequest());
  }, []);

  const companiesColumns = companiesConfig({});

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [searchString, setSearchString] = React.useState("");
  const [data, setData] = React.useState([]);
  useEffect(() => {
    if (companies) {
      let companiess = companies?.filter(
        (element) => element.tysTuru == "TEDARIKCI_FIRMA"
      );
      setData(companiess);
    }
  }, [companies]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    console.log("New page=>", newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    console.log(event.target.value);
    console.log(page);
    setPage(0);
  };

  function paginate(array, page_size, page_number) {
    page_number = page_number + 1;
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }

  const searchFilterByName = (array, searchString) => {
    return array?.filter((row) =>
      row?.adi
        .toLocaleLowerCase("tr")
        .includes(searchString.toLocaleLowerCase("tr"))
    );
  };

  const handleSearch = (event) => {
    setSearchString(event.target.value);
  };

  const filter = () => {
    let searchFilteredArr = searchFilterByName(companies, searchString);
    let paginatedArr = paginate(searchFilteredArr, rowsPerPage, page);
    setData(paginatedArr);
    return paginatedArr;
  };

  useEffect(() => {
    filter(searchString);
  }, [searchString, rowsPerPage, page]);

  return (
    <>
      <Container maxWidth="xxl">
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: 700,
          }}
        >
          GAZBİR GAZMER Onaylı Tedarikçi Firmalar
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            fontStyle: "italic",
          }}
        >
          Aşağıda tüm GAZBİR GAZMER onaylı tedarikçi firmalar listelenmiştir.
        </Typography>

        <TextField
          id="outlined-basic"
          label="Firma ara"
          variant="outlined"
          onChange={handleSearch}
          sx={{ marginBottom: "32px", marginTop: "32px" }}
        />
        <GTable
          columns={companiesColumns}
          listedData={data}
          count={companies?.length}
          padding={"16px"}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          isPaginationShow={true}
        ></GTable>
      </Container>
    </>
  );
};

export default Companies;
