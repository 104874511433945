import { useRoutes } from "react-router-dom";
import GDocument from "../components/GDocument/GDocument";
import { PublicLayout, MainLayout } from "../layouts";
import {
  Certificates,
  Error,
  Homepage,
  SignIn,
  Companies,
  Spesifications,
  AdvancedSearch,
} from "../pages";
import LandingPage from "../pages/PublicLayout/LandingPage/LandingPage";

export default function Router() {
  let mainRoutes = useRoutes([
    {
      element: <PublicLayout />,
      children: [
        { path: "*", element: <Error /> },
        { path: "/", element: <LandingPage /> },
        { path: "/signin", element: <SignIn /> },
        {
          path: "/viewBatch/:id",
          element: <GDocument />,
        },

        {
          path: "/gelismisarama",
          element: <AdvancedSearch />,
        },
      ],
    },
    {
      element: <MainLayout />,
      children: [
        {
          path: "/home",
          element: <Homepage />,
        },
        {
          path: "/certificates",
          element: <Certificates />,
        },
        {
          path: "/companies",
          element: <Companies />,
        },
        {
          path: "/spesifications",
          element: <Spesifications />,
        },
      ],
    },
  ]);

  return mainRoutes;
}
