import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { apiCall, tysApiCall } from "../../../utils/util";
import { GET_COMPANIES_SERVICE_REQUEST } from "../../actions";
import {
  getCompaniesServiceFailed,
  getCompaniesServiceSucceed,
} from "../companyService";

function* watchGetCompaniesService() {
  yield takeLatest(GET_COMPANIES_SERVICE_REQUEST, getCompanies);
}

function* getCompanies() {
  try {
    const res = yield call(tysApiCall, "post", "FindAllFirmaBilgileri");

    yield put(
      getCompaniesServiceSucceed({
        data: res.data.tysFirmaBilgileriDtoList.filter(
          (element) => element.tysTuru == "TEDARIKCI_FIRMA"
        ),
      })
    );
  } catch (err) {
    yield put(getCompaniesServiceFailed());
  }
}

export default function* userSaga() {
  yield all([fork(watchGetCompaniesService)]);
}
