import {
  Button,
  Container,
  FormControl,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment-timezone";
import { QRCodeSVG } from "qrcode.react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import NewCertificateForm from "../../../components/Forms/NewCertificateForm";
import GTable from "../../../components/GTable/GTable";
import {
  authCertificatesConfig,
  certificatesConfig,
} from "../../../constants/columnsConfigurations";
import {
  getAllSpesificationsServiceRequest,
  getSpesificationsServiceRequest,
} from "../../../services/spesification/spesificationService";
import {
  aggregateBcDataAndTysData,
  findCompanyId,
  trimBcData,
} from "../../../utils/util";
import { validate } from "../../../utils/validate";
import { getCompaniesServiceRequest } from "../../../services/company/companyService";
const Tubu = require("@tubu/tubuio-sdk-node");
const Certificates = () => {
  const navigate = useNavigate();
  const app = new Tubu("aed9548e-9130-4535-8808-05626caf786b");
  const contract = app.contract("fbf994a2b2e94c0b");

  const [setlectedData, setSelectedData] = React.useState(null);
  const [status, setStatus] = React.useState(null);

  console.log(setlectedData);
  const checkDataInBlockchain = (data) => {
    if (bcData) {
      for (const item of bcData) {
        if (
          item._company[1] === data.firmaAdi &&
          item._specification[1] === data.sartnameAdi &&
          item._createdDate === data.sertifikaTarihi &&
          item._expiredDate === data.vizeGecerlilikTarihi
        ) {
          return item._certificate_id;
        }
      }
      return false;
    }
  };

  const findSpecificationId = (data) => {
    const found = allSpesifications.find(
      (element) => element.sartnameAdi === data
    );
    if (found) {
      return found.sartnameKodu;
    }
    return "";
  };

  const sendToBlockchain = (data) => {
    const firmaId = findCompanyId(companies, data.firmaAdi);
    contract
      .send("createCertificate", {
        args: [
          "",
          [findSpecificationId(data?.sartnameAdi), data?.sartnameAdi],
          "ACTIVE",
          [firmaId, data?.firmaAdi, data?.firmaUnvani, "ACTIVE"],
          moment
            .tz(data?.sertifikaTarihi, "Europe/Istanbul")
            .startOf("day")
            .format(),
          moment
            .tz(data?.vizeGecerlilikTarihi, "Europe/Istanbul")
            .startOf("day")
            .format(),
        ],
        account: "0x2f3508DA995b3155C234233C2F42c5889630F3D4",
      })
      .then((result) => {
        Swal.fire("Başarılı", "Sertifika oluşturuldu.", "success").then(
          (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              window.location.reload();
            }
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateDataBlockchain = (data, status) => {
    contract
      .send("updateCertificate", {
        args: [
          [
            "",
            data?.certificateId,
            [findSpecificationId(data?.sartnameAdi), data?.sartnameAdi],
            status,
            [
              data?.firmaId
                ? data?.firmaId
                : findCompanyId(companies, data.firmaAdi),
              data?.firmaAdi,
              data?.firmaUnvani,
              "ACTIVE",
            ],
            moment
              .tz(data?.sertifikaTarihi, "Europe/Istanbul")
              .startOf("day")
              .format(),
            moment
              .tz(data?.vizeGecerlilikTarihi, "Europe/Istanbul")
              .startOf("day")
              .format(),
          ],
        ],
        account: "0x2f3508DA995b3155C234233C2F42c5889630F3D4",
      })
      .then((result) => {
        Swal.fire("Başarılı", "Durum Guncellendi", "success").then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClickOnView = ({ id }) => {
    navigate("/viewBatch/" + id);
  };

  const handleChange = (event) => {
    updateDataBlockchain(setlectedData, event.target.value);
  };
  const [showModal, setShowModal] = React.useState(false);

  const handleShowModal = (row) => {
    setSelectedData(row);
    contract
      .call("getCertificate", row.certificateId)
      .then((result) => {
        setStatus(result.data[3]);
      })
      .catch((err) => {
        console.log(err);
      });
    setShowModal(true);
  };
  const authCertificatesColumns = authCertificatesConfig({
    check: checkDataInBlockchain,
    onClick: sendToBlockchain,
    onView: handleClickOnView,
    handleChange,
    handleShowModal,
  });
  const certificatesColumns = certificatesConfig({
    check: checkDataInBlockchain,
    onClick: sendToBlockchain,
    onView: handleClickOnView,
  });

  const { spesifications, allSpesifications } = useSelector(
    (state) => state.spesification
  );

  const { companies } = useSelector((state) => state.company);

  const { user } = useSelector((state) => state.user);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [searchString, setSearchString] = React.useState("");
  const [data, setData] = React.useState([]);
  const [bcData, setBcData] = React.useState(null);
  const [allData, setAllData] = React.useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSpesificationsServiceRequest());
    dispatch(getAllSpesificationsServiceRequest());
    dispatch(getCompaniesServiceRequest());
  }, []);

  useEffect(() => {
    getBlockchainData();
  }, []);

  console.log(bcData);
  useEffect(() => {
    if (bcData && spesifications) {
      if (user?.isAdmin) {
        setData(aggregateBcDataAndTysData(bcData, spesifications));
        setAllData(aggregateBcDataAndTysData(bcData, spesifications));
      } else {
        setData(
          aggregateBcDataAndTysData(bcData, spesifications).filter(
            (element) =>
              element.firmaUnvani ==
              user?.tysKullaniciBilgileriDto?.temsilciOlduguFirmaList[0]
                ?.firmaTicariUnvan
          )
        );
        setAllData(
          aggregateBcDataAndTysData(bcData, spesifications).filter(
            (element) =>
              element.firmaUnvani ==
              user?.tysKullaniciBilgileriDto?.temsilciOlduguFirmaList[0]
                ?.firmaTicariUnvan
          )
        );
      }
    }
  }, [bcData, spesifications, user]);
  const handleChangePage = (event, newPage) => {
    setData(allData);
    setPage(newPage);
    console.log("New page=>", newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setData(allData);
    setRowsPerPage(event.target.value);
    console.log(event.target.value);
    console.log(page);
    setPage(0);
  };

  function paginate(array, page_size, page_number) {
    page_number = page_number + 1;
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }
  const searchFilterByName = (array, searchString) => {
    if (searchString == "") {
      return array;
    }
    return array.filter(
      (row) =>
        row.firmaAdi
          .toLocaleLowerCase("tr")
          .includes(searchString.toLocaleLowerCase("tr")) ||
        row.firmaUnvani
          .toLocaleLowerCase("tr")
          .includes(searchString.toLocaleLowerCase("tr")) ||
        row.sartnameAdi
          .toLocaleLowerCase("tr")
          .includes(searchString.toLocaleLowerCase("tr"))
    );
  };

  const handleSearch = (event) => {
    setPage(0);
    setRowsPerPage(20);
    setData(allData);
    setSearchString(event.target.value);
  };

  const filter = () => {
    let searchFilteredArr = searchFilterByName(data, searchString);
    let paginatedArr = paginate(searchFilteredArr, rowsPerPage, page);
    setData(paginatedArr);
    return paginatedArr;
  };

  useEffect(() => {
    filter(searchString);
  }, [searchString, rowsPerPage, page]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    zIndex: 1,
  };
  const initialFormData = {
    uretici_firma: "",
    sertifika_tipi: "",
    sertifika_tarihi: moment(),
    vize_gecerlilik_tarihi: moment().add(1, "y"),
  };

  const initialFormDataValidate = {
    uretici_firma: {
      required: true,
    },
    sertifika_tipi: {
      required: true,
    },
  };
  const [formData, setFormData] = useState({ ...initialFormData });
  const [formDataErrors, setFormDataErrors] = useState({});

  const [formDataValidate, setFormDataValidate] = useState({
    ...initialFormDataValidate,
  });

  const handleOnChange = (name, value) => {
    formDataErrors[name] &&
      setFormDataErrors((prevState) => ({
        ...prevState,
        [name]: "",
      }));

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const checkValidation = () => {
    const errorMsgs = validate(formData, formDataValidate);
    if (Object.keys(errorMsgs).length > 0) {
      setFormDataErrors({ ...errorMsgs });
      return false;
    }
    return true;
  };

  const handleClickOnSave = () => {
    const check = checkValidation();
    if (check) {
      contract
        .send("createCertificate", {
          args: [
            "",
            [formData?.sertifika_tipi, formData?.sertifika_tipi_text],
            "ACTIVE",
            [
              formData?.uretici_firma_id.toString(),
              formData?.uretici_firma,
              formData?.uretici_firma_text,
              "ACTIVE",
            ],
            moment
              .tz(formData?.sertifika_tarihi, "Europe/Istanbul")
              .startOf("day")
              .format(),
            moment
              .tz(formData?.vize_gecerlilik_tarihi, "Europe/Istanbul")
              .startOf("day")
              .format(),
          ],
          account: "0x2f3508DA995b3155C234233C2F42c5889630F3D4",
        })
        .then((result) => {
          setOpen(false);
          getBlockchainData();
          Swal.fire("Başarılı", "Sertifika oluşturuldu.", "success").then(
            (result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                window.location.reload();
              }
            }
          );
        })
        .catch((err) => {
          console.log(err);
        });
      /**/
    }
  };

  const getBlockchainData = () => {
    contract
      .event("CreateCertificate")
      .then((result) => {
        //filter data by status.
        const uniqueData = Array.from(
          result.data
            .reduce((map, obj) => {
              map.set(obj.returnValues._certificate_id, obj);
              return map;
            }, new Map())
            .values()
        );
        let filteredData = uniqueData.filter((data) => {
          return (
            data.returnValues._status == "ACTIVE" ||
            data.returnValues._status == "ACTIVE"
          );
        });
        setBcData(trimBcData(filteredData));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Container maxWidth="xxl">
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: 700,
          }}
        >
          Sertifikalar
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            fontStyle: "italic",
          }}
        >
          Aşağıda tüm sertifikalar listelenmiştir.
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginY: "32px",
          }}
        >
          <TextField
            label="Sertifika ara"
            variant="outlined"
            onChange={handleSearch}
          />
          <Button
            onClick={handleOpen}
            variant="contained"
            sx={{
              display: user?.isAdmin ? "block" : "none",
            }}
          >
            Sertifika Ekle
          </Button>
        </Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Sertifika Ekle
            </Typography>
            <NewCertificateForm
              formData={formData}
              formDataErrors={formDataErrors}
              handleOnChange={handleOnChange}
            />
            <Button variant="contained" onClick={handleClickOnSave}>
              Oluştur
            </Button>
          </Box>
        </Modal>
        <Modal
          open={showModal}
          onClose={() => setShowModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Ayarlar
            </Typography>

            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={status}
                size="small"
                onChange={(event) => {
                  handleChange(event);
                }}
                sx={{
                  padding: 0,
                  margin: 0,
                  fontSize: "14px",
                  width: "100px",
                }}
              >
                <MenuItem value={"PASSIVE"}>ASKIDA</MenuItem>
                <MenuItem value={"ACTIVE"}>AKTİF</MenuItem>
                <MenuItem value={"CANCEL"}>İPTAL</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Modal>

        {allData && (
          <GTable
            columns={
              user?.isAdmin ? authCertificatesColumns : certificatesColumns
            }
            listedData={data}
            count={allData?.length}
            padding={"16px"}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isPaginationShow={true}
          ></GTable>
        )}
      </Container>
    </>
  );
};

export default Certificates;
