import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import { useState } from 'react';

const GTable = ({
  columns,
  listedData,
  rowKey,
  bodySize,
  border,
  padding,
  headColor,
  textColor,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  count,
  isPaginationShow,
  size,
  mini,
}) => {

  const [sortBy, setSortBy] = useState(""); // Column to sort by
  const [sortOrder, setSortOrder] = useState("asc"); // Sorting order (asc or desc)
  const handleSort = (columnSelector) => {
    // Toggle sorting order if the same column is clicked
    if (sortBy === columnSelector) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      // Sort by the selected column in ascending order by default
      setSortBy(columnSelector);
      setSortOrder("asc");
    }
  };
  const sortedData = [...listedData].sort((a, b) => {
    if (sortOrder === "asc") {
      return a[sortBy] < b[sortBy] ? -1 : 1;
    } else {
      return a[sortBy] > b[sortBy] ? -1 : 1;
    }
  });
  return (
    <>
      <TableContainer sx={{ minHeight: "260px" }}>
        {sortedData.length > 0 ? (
          <>
            <Table
              sx={{ width: "100%" }}
              aria-label="customized table"
              size={size}
            >
              <TableHead
                sx={{
                  backgroundColor: headColor
                    ? headColor
                    : (theme) => theme.palette.primary.darkBlue,
                }}
              >
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell
                      sx={{
                        color: textColor ? textColor : "white",
                        fontSize: mini === true ? "12px" : "16px",
                        lineHeight: mini === true && 1,
                        cursor: 'pointer'
                      }}
                      key={`${column.selector}-${index}`}
                      width={column.width}
                      align={column.align && "right"}
                      onClick={() => handleSort(column.selector)}
                    >
                      {column.name}
                      {sortBy === column.selector && ( // Display sorting indicator
          <span >{sortOrder === "asc" ? " ▲" : " ▼"}</span>
        )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                <>
                  {sortedData.map((dataItem, index) => (
                    <TableRow key={`${dataItem[rowKey]}-${index}`}>
                      {columns.map((column, cIndex) => (
                        <TableCell
                          key={`${dataItem[rowKey]}-${column.selector}-${cIndex}`}
                          title={column.showTitle && dataItem[column.selector]}
                          align={column.align ? column.align : "left"}
                          sx={{
                            padding: padding ? padding : "5.6px 0px",
                            fontSize: bodySize ? 12 : 16,
                            borderBottom: border ? 0 : "1px solid #C3C5D3",
                          }}
                        >
                          {column.cell
                            ? column.cell(dataItem)
                            : dataItem[column.selector]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </>
              </TableBody>
            </Table>
          </>
        ) : (
          <>Veri yok.</>
        )}
      </TableContainer>
      {isPaginationShow ? (
        <TablePagination
          sx={{
            minHeight: "52px",
            color: (theme) => theme.palette.primary.white,
          }}
          rowsPerPageOptions={[3, 5, 10, 20, 25, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={"Sayfa Başına Satır"}
        />
      ) : (
        isPaginationShow
      )}
    </>
  );
};

export default GTable;
