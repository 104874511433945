import * as services from "../actions";

export const getSpesificationsServiceRequest = (reqParams) => ({
  type: services.GET_SPESIFICATIONS_SERVICE_REQUEST,
  payload: reqParams,
});

export const getSpesificationsServiceSucceed = (reqParams) => ({
  type: services.GET_SPESIFICATIONS_SERVICE_SUCCEED,
  payload: reqParams,
});

export const getSpesificationsServiceFailed = () => ({
  type: services.GET_SPESIFICATIONS_SERVICE_FAILED,
});

export const getAllSpesificationsServiceRequest = (reqParams) => ({
  type: services.GET_ALL_SPESIFICATIONS_SERVICE_REQUEST,
  payload: reqParams,
});

export const getAllSpesificationsServiceSucceed = (reqParams) => ({
  type: services.GET_ALL_SPESIFICATIONS_SERVICE_SUCCEED,
  payload: reqParams,
});

export const getAllSpesificationsServiceFailed = () => ({
  type: services.GET_ALL_SPESIFICATIONS_SERVICE_FAILED,
});
