import React, { useEffect } from "react";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAllSpesificationsServiceRequest } from "../../../services/spesification/spesificationService";
import moment from "moment";
import { useNavigate } from "react-router-dom";
const Orders = ({ data }) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);

  return (
    <>
      <Typography component="h2" variant="h6" gutterBottom>
        Son Sertifikalar
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Firma Unvanı</TableCell>
            <TableCell>Şartname Adı</TableCell>
            <TableCell>Sertifika Tarihi</TableCell>
            <TableCell>Vize Geçerlilik Tarihi</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            ?.filter((element) => {
              if (user?.tysKullaniciBilgileriDto) {
                return (
                  element.firmaUnvani ==
                  user?.tysKullaniciBilgileriDto?.temsilciOlduguFirmaList[0]
                    ?.firmaTicariUnvan
                );
              } else {
                return element;
              }
            })
            ?.slice(0, 5)
            .map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.firmaUnvani}</TableCell>
                <TableCell>{row.sartnameAdi}</TableCell>
                <TableCell>
                  {moment(row.sertifikaTarihi).format("DD/MM/YYYY")}
                </TableCell>
                <TableCell>
                  {moment(row.vizeGecerlilikTarihi).format("DD/MM/YYYY")}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Link
        onClick={() => navigate("/certificates")}
        sx={{ mt: 3, cursor: "pointer" }}
      >
        Daha fazlasını gör
      </Link>
    </>
  );
};

export default Orders;
