import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useDispatch, useSelector } from "react-redux";
import { getAllSpesificationsServiceRequest } from "../../services/spesification/spesificationService";
import { getCompaniesServiceRequest } from "../../services/company/companyService";

const NewCertificateForm = ({ formData, formDataErrors, handleOnChange }) => {
  const handleOnChangeDateBas = (newValue) => {
    handleOnChange("sertifika_tarihi", newValue);
  };
  const handleOnChangeDateBit = (newValue) => {
    handleOnChange("vize_gecerlilik_tarihi", newValue);
  };
  const { allSpesifications } = useSelector((state) => state.spesification);
  const { companies } = useSelector((state) => state.company);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllSpesificationsServiceRequest());
    dispatch(getCompaniesServiceRequest());
  }, []);

  return (
    <FormControl sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
        <FormControl sx={{ width: "100%" }}>
          <InputLabel id="uretici_firma">Üretici Firma</InputLabel>
          <Select
            label="Üretici Firma"
            labelId="uretici_firma"
            name="uretici_firma"
            error={formDataErrors?.uretici_firma}
            value={formData?.uretici_firma || ""}
            onChange={(e, index) => {
              console.log(index.props);
              handleOnChange(e.target.name, e.target.value);
              handleOnChange("uretici_firma_text", index.props.children);
              handleOnChange("uretici_firma_id", index.props.data_id);
            }}
          >
            {companies.map((item, index) => (
              <MenuItem key={index} data_id={item?.id} value={item?.adi}>
                {item?.unvani}
              </MenuItem>
            ))}
          </Select>
          {formDataErrors?.uretici_firma && (
            <FormHelperText sx={{ color: "red" }}>Gereklidir</FormHelperText>
          )}
        </FormControl>
        <FormControl sx={{ width: "100%" }}>
          <InputLabel id="sertifika_tipi">Sertifika</InputLabel>
          <Select
            label="Serfitika"
            labelId="sertifika_tipi"
            name="sertifika_tipi"
            error={formDataErrors?.sertifika_tipi}
            value={formData?.sertifika_tipi || ""}
            onChange={(e, index) => {
              handleOnChange(e.target.name, e.target.value);
              handleOnChange("sertifika_tipi_text", index.props.children);
            }}
          >
            {allSpesifications.map((item, index) => (
              <MenuItem key={index} value={item?.sartnameKodu}>
                {item?.sartnameAdi}
              </MenuItem>
            ))}
          </Select>
          {formDataErrors?.sertifika_tipi && (
            <FormHelperText sx={{ color: "red" }}>Gereklidir</FormHelperText>
          )}
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Stack sx={{ width: "100%" }}>
            <DesktopDatePicker
              label="Sertifika Tarihi"
              format="DD/MM/YYYY"
              name="sertifika_tarihi"
              value={formData?.sertifika_tarihi || moment()}
              renderInput={(params) => <TextField size="small" {...params} />}
              onChange={handleOnChangeDateBas}
            />
          </Stack>
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Stack sx={{ width: "100%" }}>
            <DesktopDatePicker
              label="Vize Geçerlilik Tarihi"
              format="DD/MM/YYYY"
              name="vize_gecerlilik_tarihi"
              value={formData?.vize_gecerlilik_tarihi || moment().add(1, "y")}
              renderInput={(params) => <TextField size="small" {...params} />}
              onChange={handleOnChangeDateBit}
            />
          </Stack>
        </LocalizationProvider>
      </Box>
    </FormControl>
  );
};

export default NewCertificateForm;
