import * as actions from "../../actions";

const initialState = {
  spesifications: [],
  allSpesifications: [],
};

const spesification = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_SPESIFICATIONS_SERVICE_REQUEST:
      return {
        ...state,
      };
    case actions.GET_SPESIFICATIONS_SERVICE_SUCCEED:
      return {
        ...state,
        spesifications: action.payload.data?.tfrFirmaSartnameOnayDtoList,
      };
    case actions.GET_SPESIFICATIONS_SERVICE_FAILED:
      return {
        ...state,
      };
    case actions.GET_ALL_SPESIFICATIONS_SERVICE_REQUEST:
      return {
        ...state,
      };
    case actions.GET_ALL_SPESIFICATIONS_SERVICE_SUCCEED:
      return {
        ...state,
        allSpesifications: action.payload.data?.tsbTanimDtoList,
      };
    case actions.GET_ALL_SPESIFICATIONS_SERVICE_FAILED:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default spesification;
