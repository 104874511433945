import * as actions from "../../actions";

const initialState = {
  user: {},
  login: null,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case actions.POST_LOGIN_SERVICE_REQUEST:
      return {
        ...state,
        login: null,
      };
    case actions.POST_LOGIN_SERVICE_SUCCEED:
      return {
        ...state,
        login: action.payload.data,
      };
    case actions.POST_LOGIN_SERVICE_FAILED:
      return {
        ...state,
      };
    case actions.GET_USERS_SERVICE_REQUEST:
      return {
        ...state,
      };
    case actions.GET_USERS_SERVICE_SUCCEED:
      return {
        ...state,
        users: action.payload.data,
      };
    case actions.GET_USERS_SERVICE_FAILED:
      return {
        ...state,
      };
    case actions.GET_USER_INFO_SERVICE_REQUEST:
      return {
        ...state,
      };
    case actions.GET_USER_INFO_SERVICE_SUCCEED:
      return {
        ...state,
        user: action.payload.data,
      };
    case actions.GET_USER_INFO_SERVICE_FAILED:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default user;
