import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import GSearch from "../../../components/GSearch/GSearch";
import GAlbum from "../../../components/GAlbum/GAlbum";
import bg from "../../../assets/bg.png";
import logo from "../../../assets/logo.png";

const post = {
  title: "GAZBİR GAZMER Blok Zinciri Sertifika Sistemi",
  description: "",
  image: bg,
  imageText: "main image description",
  linkText: "Continue reading…",
};

function Copyright(props) {
  return (
    <Typography variant="body2" align="center" {...props}>
      {"Copyright © "}
      <Link href="https://www.gazmer.com.tr/Default">GAZBİR GAZMER</Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const LandingPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <React.Fragment>
        <GlobalStyles
          styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
        />
        <CssBaseline />
        <AppBar position="static" color="default" elevation={0}>
          <Toolbar sx={{ flexWrap: "wrap" }}>
            <Box
              sx={{
                flexGrow: 1,
              }}
            >
              <img
                src={logo}
                width="160"
                style={{
                  display: {
                    xs: "flex",
                    md: "none",
                    marginTop: "-20px",
                  },
                }}
              />
            </Box>
            <Button
              variant="text"
              sx={{ my: 1, mx: 1.5, color: "black " }}
              onClick={() => navigate("signin")}
            >
              SİSTEME GİRİŞ
            </Button>
          </Toolbar>
        </AppBar>
        {/* Hero unit */}
        <Paper
          sx={{
            position: "relative",
            backgroundColor: "grey.800",
            color: "#fff",
            mb: 4,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundImage: `url(${post.image})`,
          }}
        >
          {/* Increase the priority of the hero background image */}
          {
            <img
              style={{ display: "none" }}
              src={post.image}
              alt={post.imageText}
            />
          }
          <Box
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              backgroundColor: "rgba(0,0,0,.3)",
            }}
          />
          <Grid container>
            <Grid item md={6}>
              <Box
                sx={{
                  position: "relative",
                  p: { xs: 3, md: 6 },
                  pr: { md: 0 },
                }}
              >
                <Typography
                  component="h1"
                  variant="h3"
                  color="inherit"
                  gutterBottom
                >
                  {post.title}
                </Typography>
                <Typography variant="h5" color="inherit" paragraph>
                  {post.description}
                </Typography>
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box
                sx={{
                  position: "relative",
                  p: { xs: 3, md: 6 },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <GSearch></GSearch>
              </Box>
            </Grid>
          </Grid>
        </Paper>
        {/* End hero unit */}
        <Container maxWidth="sm" component="main">
          <GAlbum></GAlbum>
        </Container>
        {/* Footer */}
        <Container
          maxWidth="md"
          component="footer"
          sx={{
            mt: 8,
            py: [3, 6],
          }}
        >
          <Copyright sx={{ mt: 5 }} />
        </Container>
        {/* End footer */}
      </React.Fragment>
    </>
  );
};

export default LandingPage;
