import { CircularProgress } from "@mui/material";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
  Font,
  Svg,
} from "@react-pdf/renderer";
import moment from "moment";
import QRCode from "qrcode";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import bgpdf from "../../assets/bgpdf.png";
import logo3 from "../../assets/logo3.png";

const Tubu = require("@tubu/tubuio-sdk-node");
const app = new Tubu("aed9548e-9130-4535-8808-05626caf786b");
const contract = app.contract("fbf994a2b2e94c0b");
// Create styles fbf994a2b2e94c0b
Font.register({
  family: "Roboto",
  fonts: [
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/materialize/0.98.1/fonts/roboto/Roboto-Regular.ttf",
    },
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/materialize/0.98.1/fonts/roboto/Roboto-Bold.ttf",
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    color: "black",
  },
  section: {
    margin: 10,
    padding: 10,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  image: {
    width: 200,
    height: 100,
    marginLeft: 240,
    marginTop: "-10px",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 12,
    textAlign: "center",
    marginTop: "-20px",
  },
  desc: {
    marginTop: "20px",
    fontFamily: "Roboto",
    fontSize: 12,
  },
  desc2: {
    marginTop: "20px",
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: "700",
    textAlign: "justify",
  },
  title2: {
    marginTop: "10px",
    fontFamily: "Roboto",
    fontSize: 14,
    width: 300,
  },
  table: {
    fontFamily: "Roboto",

    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: "20px",
  },
  tableRow: {
    fontFamily: "Roboto",

    flexDirection: "row",
  },
  tableCol: {
    fontFamily: "Roboto",

    width: "34%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    fontFamily: "Roboto",
    marginLeft: "5px",
    marginVertical: 20,
    fontSize: 10,
  },
  president: {
    fontFamily: "Roboto",
    fontSize: 14,
  },
  last: {
    fontFamily: "Roboto",
    fontSize: 9.5,
  },
  pageBackground: {
    position: "absolute",
    width: "100%",
    height: "590px",
  },
});

// Create Document Component
const GDocument = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [QRCodeString, setQRCodeString] = useState(null);
  useEffect(() => {
    contract
      .call("getCertificate", id)
      .then((result) => {
        setData(result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  useEffect(() => {
    QRCode.toDataURL(window.location.hostname + "/viewBatch/" + id).then(
      (data) => {
        setQRCodeString(data);
      }
    );
  }, []);

  moment.locale("tr");

  return (
    <>
      {data && QRCodeString ? (
        <PDFViewer style={styles.viewer}>
          {/* Start of the document*/}

          <Document>
            {/*render a single page*/}
            <Page size="A4" style={styles.page} orientation="landscape">
              <Image src={bgpdf} style={styles.pageBackground} />
              <View
                style={{
                  paddingLeft: "80px",
                  paddingRight: "80px",
                  height: "100%",
                }}
              >
                <Image src={logo3} style={styles.image} />
                <Text style={styles.title}>
                  GAZBİR GAZMER ONAYLI TEDARİKÇİ BELGESİ
                </Text>
                <Text style={styles.desc}>
                  Belirtilen firma ve ürüne ait standart belgeleri, test muayene
                  raporları, kalite dökümanları, servis ve garanti belgeleri;
                  GAZBİR GAZMER Tedarik Yönetim Sistemi Denetim Komisyonu
                  tarafından, Tedarik Yönetim Sistemi kapsamında incelenmiş
                  olup; ekli lisans bölgelerimizde belirtilen geçerlilik tarihi
                  kapsamında kullanımına müsaade edilmiştir.
                </Text>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "10px",
                  }}
                >
                  <Text style={styles.title2}>ÜRETİCİ / İTHALATCI FİRMA</Text>
                  <Text style={styles.title2}>: {data[4][2]}</Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Text style={styles.title2}>
                    ÜRÜN / ŞARTNAME KODU - ÜRETİM YERİ
                  </Text>
                  <Text style={styles.title2}>: {data[2][0]} - İSTANBUL</Text>
                </View>
                <View style={styles.table}>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>ŞARTNAME</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>SERTİFİKA TARİHİ</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        VİZE GEÇERLİLİK TARİHİ
                      </Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {data[2][0] + " " + data[2][1]}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {moment(data[5]).format("DD/MM/YYYY")}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {moment(data[6]).format("DD/MM/YYYY")}
                      </Text>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    width: "auto",
                    marginTop: "20px",
                  }}
                >
                  <Text style={styles.president}>Yaşar ARSLAN</Text>
                  <Text style={styles.president}>BAŞKAN</Text>
                </View>

                <Image
                  src={QRCodeString}
                  style={{ width: "100px", height: "100px" }}
                />
                <View
                  style={{
                    position: "absolute",
                    left: 80,
                    bottom: 20,
                  }}
                >
                  <Text style={styles.last}>Batch No: {id}</Text>
                  <Text style={styles.last}>
                    "Bu belge kapsamında ürün, geçerlilik tarihine kadar
                    kullanılabilir. Süre bitiminde, firma tarafından vize
                    yenileme işlemi yapılmalıdır."
                  </Text>
                  <Text style={styles.last}>
                    "Bu belge ilgili ürünlerin standartlar ve mevzuat açısından
                    incelendiğini ve doğal gaz dağıtım sektöründe kullanım
                    uygunluğunu belirtmekle beraber; ürün kalite belgesi{" "}
                    <br></br>
                    hükmü taşımaz."
                  </Text>
                </View>
              </View>
            </Page>
            <Page size="A4" style={styles.page} orientation="landscape">
              <Image src={bgpdf} style={styles.pageBackground} />

              <View
                style={{
                  paddingLeft: "80px",
                  paddingRight: "80px",
                  height: "100%",
                }}
              >
                <Image src={logo3} style={styles.image} />
                <Text style={styles.title}>
                  GAZBİR GAZMER ONAYLI TEDARİKÇİ BELGESİ
                </Text>
                <Text style={styles.desc2}>
                  AGDAŞ ADAPAZARI GAZ DAĞITIM A.Ş, AKSA AFYON DOĞALGAZ DAĞITIM
                  A.Ş, AKSA AĞRI DOĞALGAZ DAĞITIM A.Ş, AKSA BALIKESİR DOĞALGAZ
                  DAĞITIM A.Ş, AKSA BANDIRMA DOĞALGAZ DAĞITIM A.Ş, AKSA BİLECİK
                  BOLU DOĞALGAZ DAĞITIM A.Ş, AKSA ÇANAKKALE DOĞALGAZ DAĞITIM
                  A.Ş, AKSA DÜZCE EREĞLİ DOĞALGAZ DAĞITIM A.Ş, AKSA ELAZIĞ
                  DOĞALGAZ DAĞITIM A.Ş, AKSA GAZ DOĞALGAZ DAĞITIM A.Ş, AKSA
                  GEMLİK DOĞALGAZ DAĞITIM A.Ş, AKSA GÜMÜŞHANE BAYBURT DOĞALGAZ
                  DAĞITIM A.Ş, AKSA MALATYA DOĞALGAZ DAĞITIM A.Ş, AKSA MANİSA
                  DOĞALGAZ DAĞITIM A.Ş, AKSA MUSTAFAKEMALPAŞA SUSURLUK DOĞALGAZ
                  DAĞITIM A.Ş, AKSA ORDU GİRESUN DOĞALGAZ DAĞITIM A.Ş, AKSA
                  SİİRT BATMAN DOĞALGAZ DAĞITIM A.Ş, AKSA SİVAS DOĞALGAZ DAĞITIM
                  A.Ş, AKSA ŞANLIURFA DOĞALGAZ DAĞITIM A.Ş, AKSA TOKAT AMASYA
                  DOĞALGAZ DAĞITIM A.Ş, AKSA TRABZON RİZE DOĞALGAZ DAĞITIM A.Ş,
                  AKSA VAN DOĞALGAZ DAĞITIM A.Ş, ARSAN MARAŞ DOĞAL GAZ DAĞITIM
                  A.Ş, ARSAN MARMARA DOĞAL GAZ DAĞITIM A.Ş, BAHÇEŞEHİR GAZ
                  DAĞITIM A.Ş, BAŞKENT DOĞAL GAZ DAĞITIM A.Ş, BURSA ŞEHİRİÇİ
                  DOĞAL GAZ DAĞITIM TİCARET VE TAAH A.Ş, ÇİNİGAZ DOĞAL GAZ
                  DAĞITIM SANAYİ VE TİCARET A.Ş, ÇORLU DOĞAL GAZ DAĞITIM A.Ş,
                  ÇORUM DOĞAL GAZ DAĞITIM SAN.TİC. A.Ş, DİYARBAKIR DOĞAL GAZ
                  DAĞITIM A.Ş, DOĞUGAZ BİTLİS BİNGÖL MUŞ DOĞAL GAZ DAĞITIM A.Ş,
                  ENERYA AKSARAY GAZ DAĞITIM A.Ş, ENERYA ANTALYA GAZ DAĞITIM
                  A.Ş, ENERYA AYDIN GAZ DAĞITIM A.Ş, ENERYA DENİZLİ GAZ DAĞITIM
                  A.Ş, ENERYA EREĞLİ GAZ DAĞITIM A.Ş, ENERYA ERZİNCAN GAZ
                  DAĞITIM A.Ş, ENERYA KAPADOKYA GAZ DAĞITIM A.Ş, ENERYA KARAMAN
                  GAZ DAĞITIM A.Ş, ENERYA KONYA GAZ DAĞITIM A.Ş, ESGAZ ESKİŞEHİR
                  ŞEHİRİÇİ DOĞAL GAZ DAĞITIM TİCARET VE TAAH. A.Ş, GAZDAŞ
                  GAZİANTEP DOĞAL GAZ DAĞITIM A.Ş, İNEGÖL GAZ DAĞITIM SANAYİ VE
                  TİCARET A.Ş, İZMİR DOĞALGAZ DAĞITIM TİCARET VE TAAHHÜT A.Ş,
                  İZMİT GAZ DAĞITIM SANAYİ VE TİCARET A.Ş, KARGAZ DOĞAL GAZ
                  DAĞITIM SAN.TİC. A.Ş, KARGAZ KARS ARDAHAN DOĞAL GAZ DAĞITIM
                  SAN.TİC. A.Ş, KAYSERİGAZ KAYSERİ DOĞAL GAZ DAĞITIM A.Ş, KIRGAZ
                  KIRIKKALE-KIRŞEHİR DOĞAL GAZ DAĞITIM PAZARLAMA VE TİC. A.Ş,
                  KIZILCAHAMAM DOĞAL GAZ DAĞITIM A.Ş., PALEN ENERJİ DOĞAL GAZ
                  DAĞITIM END. VE TİCARET A.Ş, PALGAZ DOĞAL GAZ DAĞITIM SANAYİ
                  VE TİCARET A.Ş, POLATLI DOĞAL GAZ DAĞITIM SANAYİ VE TİCARET
                  A.Ş, SAMGAZ DOĞAL GAZ DAĞITIM A.Ş, SELÇUK DOĞAL GAZ DAĞITIM
                  A.Ş, SERHAT DOĞAL GAZ DAĞITIM SANAYİ VE TİCARET A.Ş, SÜRMELİ
                  DOĞAL GAZ DAĞITIM SAN.TİC. A.Ş, TOROSGAZ ISPARTA BURDUR DOĞAL
                  GAZ DAĞITIM A.Ş, TRAKYA BÖLGESİ DOĞAL GAZ DAĞITIM A.Ş, UDAŞ
                  UŞAK DOĞAL GAZ DAĞITIM A.Ş
                </Text>

                <Image
                  src={QRCodeString}
                  style={{ width: "100px", height: "100px" }}
                />
                <View
                  style={{
                    position: "absolute",
                    left: 80,
                    bottom: 20,
                  }}
                >
                  <Text style={styles.last}>Batch No: {id}</Text>
                  <Text style={styles.last}>
                    "Bu belge kapsamında ürün, geçerlilik tarihine kadar
                    kullanılabilir. Süre bitiminde, firma tarafından vize
                    yenileme işlemi yapılmalıdır."
                  </Text>
                  <Text style={styles.last}>
                    "Bu belge ilgili ürünlerin standartlar ve mevzuat açısından
                    incelendiğini ve doğal gaz dağıtım sektöründe kullanım
                    uygunluğunu belirtmekle beraber; ürün kalite belgesi{" "}
                    <br></br>
                    hükmü taşımaz."
                  </Text>
                </View>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      ) : (
        <CircularProgress />
      )}
    </>
  );
};
export default GDocument;
