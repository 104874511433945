//Public Layout
import Homepage from "./MainLayout/Homepage/Homepage";
import Certificates from "./MainLayout/Certificates/Certificates";
import Companies from "./MainLayout/Companies/Companies";
import Spesifications from "./MainLayout/Spesifications/Spesifications";

//Main Layout
import Error from "./PublicLayout/Error/Error";
import SignIn from "./PublicLayout/SignIn/SignIn";
import AdvancedSearch from "./PublicLayout/AdvancedSearch/AdvancedSearch";

export {
  Homepage,
  Error,
  SignIn,
  Certificates,
  Companies,
  Spesifications,
  AdvancedSearch,
};
