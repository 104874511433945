// USER
export const GET_USERS_SERVICE_REQUEST = "GET_USERS_SERVICE_REQUEST";
export const GET_USERS_SERVICE_SUCCEED = "GET_USERS_SERVICE_SUCCEED";
export const GET_USERS_SERVICE_FAILED = "GET_USERS_SERVICE_FAILED";

export const POST_LOGIN_SERVICE_REQUEST = "POST_LOGIN_SERVICE_REQUEST";
export const POST_LOGIN_SERVICE_SUCCEED = "POST_LOGIN_SERVICE_SUCCEED";
export const POST_LOGIN_SERVICE_FAILED = "POST_LOGIN_SERVICE_FAILED";

export const GET_USER_INFO_SERVICE_REQUEST = "GET_USER_INFO_SERVICE_REQUEST";
export const GET_USER_INFO_SERVICE_SUCCEED = "GET_USER_INFO_SERVICE_SUCCEED";
export const GET_USER_INFO_SERVICE_FAILED = "GET_USER_INFO_SERVICE_FAILED";

// CERTIFICATE
export const GET_CERTIFICATES_SERVICE_REQUEST =
  "GET_CERTIFICATES_SERVICE_REQUEST";
export const GET_CERTIFICATES_SERVICE_SUCCEED =
  "GET_CERTIFICATES_SERVICE_SUCCEED";
export const GET_CERTIFICATES_SERVICE_FAILED =
  "GET_CERTIFICATES_SERVICE_FAILED";

// COMPANY
export const GET_COMPANIES_SERVICE_REQUEST = "GET_COMPANIES_SERVICE_REQUEST";
export const GET_COMPANIES_SERVICE_SUCCEED = "GET_COMPANIES_SERVICE_SUCCEED";
export const GET_COMPANIES_SERVICE_FAILED = "GET_COMPANIES_SERVICE_FAILED";

// SPESIFICATION
export const GET_SPESIFICATIONS_SERVICE_REQUEST =
  "GET_SPESIFICATIONS_SERVICE_REQUEST";
export const GET_SPESIFICATIONS_SERVICE_SUCCEED =
  "GET_SPESIFICATIONS_SERVICE_SUCCEED";
export const GET_SPESIFICATIONS_SERVICE_FAILED =
  "GET_SPESIFICATIONS_SERVICE_FAILED";

export const GET_ALL_SPESIFICATIONS_SERVICE_REQUEST =
  "GET_ALL_SPESIFICATIONS_SERVICE_REQUEST";
export const GET_ALL_SPESIFICATIONS_SERVICE_SUCCEED =
  "GET_ALL_SPESIFICATIONS_SERVICE_SUCCEED";
export const GET_ALL_SPESIFICATIONS_SERVICE_FAILED =
  "GET_ALL_SPESIFICATIONS_SERVICE_FAILED";
