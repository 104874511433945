import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { postLoginServiceRequest } from "../../../services/user/userService";
import bg from "../../../assets/bg.png";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.gazmer.com.tr/Default">
        GAZBİR GAZMER
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const SignIn = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { login } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const handleLogin = (event) => {
    if (email && password) {
      dispatch(postLoginServiceRequest({ email, password, navigate }));
    }
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url(${bg})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            component="h1"
            variant="h4"
            sx={{
              textAlign: "center",
              fontWeight: 700,
              marginBottom: "32px",
            }}
          >
            GAZBİR GAZMER BLOK ZİNCİRİ SERTİFİKA SİSTEMİ
          </Typography>
          <Avatar
            sx={{ m: 1, bgcolor: (theme) => theme.palette.primary.lightBlue }}
          >
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Giriş yap
          </Typography>
          <Box sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Eposta"
              name="email"
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  dispatch(
                    postLoginServiceRequest({ email, password, navigate })
                  );
                }
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Parola"
              type="password"
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  dispatch(
                    postLoginServiceRequest({ email, password, navigate })
                  );
                }
              }}
            />
            <Button
              disableRipple
              fullWidth
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleLogin}
            >
              Giriş yap
            </Button>
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SignIn;
