import * as services from "../actions";

export const postLoginServiceRequest = (reqParams) => ({
  type: services.POST_LOGIN_SERVICE_REQUEST,
  payload: reqParams,
});

export const postLoginServiceSucceed = (reqParams) => ({
  type: services.POST_LOGIN_SERVICE_SUCCEED,
  payload: reqParams,
});

export const postLoginServiceFailed = () => ({
  type: services.POST_LOGIN_SERVICE_FAILED,
});

export const getUserInfoServiceRequest = (reqParams) => ({
  type: services.GET_USER_INFO_SERVICE_REQUEST,
  payload: reqParams,
});

export const getUserInfoServiceSucceed = (reqParams) => ({
  type: services.GET_USER_INFO_SERVICE_SUCCEED,
  payload: reqParams,
});

export const getUserInfoServiceFailed = () => ({
  type: services.GET_USER_INFO_SERVICE_FAILED,
});

export const getUsersServiceRequest = (reqParams) => ({
  type: services.GET_USERS_SERVICE_REQUEST,
  payload: reqParams,
});

export const getUsersServiceSucceed = (reqParams) => ({
  type: services.GET_USERS_SERVICE_SUCCEED,
  payload: reqParams,
});

export const getUsersServiceFailed = () => ({
  type: services.GET_USERS_SERVICE_FAILED,
});
