import { Container, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import GTable from "../../../components/GTable/GTable";
import { spesificationsConfig } from "../../../constants/columnsConfigurations";
import { getAllSpesificationsServiceRequest } from "../../../services/spesification/spesificationService";
const Spesifications = () => {
  const spesificationsColumns = spesificationsConfig({});

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [searchString, setSearchString] = React.useState("");
  const [data, setData] = React.useState([]);

  const { allSpesifications } = useSelector((state) => state.spesification);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllSpesificationsServiceRequest());
  }, []);

  useEffect(() => {
    if (allSpesifications) {
      setData(allSpesifications);
    }
  }, [allSpesifications]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    console.log("New page=>", newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    console.log(event.target.value);
    console.log(page);
    setPage(0);
  };
  console.log(data, "listelenen data");

  function paginate(array, page_size, page_number) {
    page_number = page_number + 1;
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }

  const searchFilterByName = (array, searchString) => {
    return array.filter((row) =>
      row.sartnameAdi
        .toLocaleLowerCase("tr")
        .includes(searchString.toLocaleLowerCase("tr"))
    );
  };

  const handleSearch = (event) => {
    setPage(0);
    setSearchString(event.target.value);
  };

  const filter = () => {
    let searchFilteredArr = searchFilterByName(allSpesifications, searchString);
    let paginatedArr = paginate(searchFilteredArr, rowsPerPage, page);
    setData(paginatedArr);
    return paginatedArr;
  };

  useEffect(() => {
    filter(searchString);
  }, [searchString, rowsPerPage, page]);

  return (
    <>
      <Container maxWidth="xxl">
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: 700,
          }}
        >
          Şartnameler
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            fontStyle: "italic",
          }}
        >
          Aşağıda tüm şartnameler listelenmiştir.
        </Typography>

        <TextField
          id="outlined-basic"
          label="Şartname ara"
          variant="outlined"
          onChange={handleSearch}
          sx={{ marginBottom: "32px", marginTop: "32px" }}
        />
        {allSpesifications && (
          <GTable
            columns={spesificationsColumns}
            listedData={data}
            count={allSpesifications?.length}
            padding={"16px"}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isPaginationShow={true}
          ></GTable>
        )}
      </Container>
    </>
  );
};

export default Spesifications;
