import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { tysApiCall } from "../../../utils/util";
import {
  GET_ALL_SPESIFICATIONS_SERVICE_REQUEST,
  GET_SPESIFICATIONS_SERVICE_REQUEST,
} from "../../actions";
import {
  getAllSpesificationsServiceFailed,
  getAllSpesificationsServiceSucceed,
  getSpesificationsServiceFailed,
  getSpesificationsServiceSucceed,
} from "../spesificationService";

function* watchGetSpesificationsService() {
  yield takeLatest(GET_SPESIFICATIONS_SERVICE_REQUEST, getSpesifications);
}

function* getSpesifications() {
  try {
    const res = yield call(
      tysApiCall,
      "post",
      "findAllTfrFirmaSartnameOnayDto"
    );
    yield put(
      getSpesificationsServiceSucceed({
        data: res.data,
      })
    );
  } catch (err) {
    yield put(getSpesificationsServiceFailed());
  }
}

function* watchGetAllSpesificationsService() {
  yield takeLatest(
    GET_ALL_SPESIFICATIONS_SERVICE_REQUEST,
    getAllSpesifications
  );
}

function* getAllSpesifications() {
  try {
    const res = yield call(tysApiCall, "post", "FindAllTsbTanim");
    yield put(
      getAllSpesificationsServiceSucceed({
        data: res.data,
      })
    );
  } catch (err) {
    yield put(getAllSpesificationsServiceFailed());
  }
}

export default function* spesificationSaga() {
  yield all([
    fork(watchGetSpesificationsService),
    fork(watchGetAllSpesificationsService),
  ]);
}
