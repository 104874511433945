import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import Swal from "sweetalert2";

const Tubu = require("@tubu/tubuio-sdk-node");
const app = new Tubu("aed9548e-9130-4535-8808-05626caf786b");
const contract = app.contract("fbf994a2b2e94c0b");
const GSearch = () => {
  const navigate = useNavigate();
  const [id, setId] = useState(null);

  console.log(id);

  const handleSearchOnClick = () => {
    contract
      .call("getCertificate", id)
      .then((result) => {
        if (result.data) {
          navigate("/viewBatch/" + id);
        } else {
          Swal.fire("Hata", "Sertifika bulunamadı", "error");
        }
      })
      .catch((err) => {
        Swal.fire("Hata", "Sertifika bulunamadı", "error");
      });
  };
  return (
    <Paper
      sx={{ p: "2px 4px", display: "flex", alignItems: "center", height: 50 }}
    >
      <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
        <SearchIcon />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Sertifika ara"
        inputProps={{ "aria-label": "search google maps" }}
        onChange={(e) => setId(e.target.value)}
      />
      <Button variant="contained" onClick={handleSearchOnClick}>
        Sertifika Ara
      </Button>

      <Button
        variant="contained"
        sx={{ ml: 1 }}
        onClick={() => navigate("/gelismisarama")}
      >
        Gelişmiş Arama
      </Button>
    </Paper>
  );
};

export default GSearch;
