import { combineReducers } from "redux";
import user from "./user/reducers/user";
import company from "./company/reducers/company";
import spesification from "./spesification/reducers/spesification";

const rootReducer = combineReducers({
  user,
  company,
  spesification,
});

export default rootReducer;
