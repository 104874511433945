import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import GSidebar from "../../components/GSidebar/GSidebar";
import { getUserInfoServiceRequest } from "../../services/user/userService";

const MainLayout = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();

  if (token) {
    dispatch(getUserInfoServiceRequest(navigate));
  }
  return (
    <>
      <GSidebar children={<Outlet />} />
    </>
  );
};

export default MainLayout;
