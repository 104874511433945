import { Settings } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
} from "@mui/material";
import moment from "moment";

export const companiesConfig = () => [
  {
    name: "Firma Unvanı",
    selector: "unvani",
  },
  {
    name: "Faaliyet Türü",
    selector: null,
  },
  {
    name: "Sabit Hat",
    selector: null,
  },
  {
    name: "E-posta Adresi",
    selector: null,
  },
  {
    name: "Web Adresi",
    selector: "webAdresi",
  },
];

export const spesificationsConfig = (asd) => [
  {
    name: "Şartname Kodu",
    selector: "sartnameKodu",
  },
  {
    name: "Şartname Adı",
    selector: "sartnameAdi",
  },
  {
    name: "Onay Tarihi",
    selector: "onayTarihi",
    cell: (row) => {
      return <>{moment(row.onayTarihi).format("L")}</>;
    },
  },
];

export const certificatesConfig = ({ check, onClick, onView }) => [
  {
    name: "Firma Adı",
    selector: "firmaAdi",
  },
  {
    name: "Firma Unvanı",
    selector: "firmaUnvani",
  },
  {
    name: "Şartname Adı",
    selector: "sartnameAdi",
  },
  {
    name: "Sertifika Tarihi",
    selector: "sertifikaTarihi",
    cell: (row) => {
      return <>{moment(row.sertifikaTarihi).format("DD/MM/YYYY")}</>;
    },
  },
  {
    name: "Vize Geçerlilik Tarihi",
    selector: "vizeGecerlilikTarihi",
    cell: (row) => {
      return <>{moment(row.vizeGecerlilikTarihi).format("DD/MM/YYYY")}</>;
    },
  },
  {
    name: "İşlemler",
    selector: "date_joined",
    cell: (row) => {
      return (
        <>
          {check(row) ? (
            <Button
              variant="contained"
              href={"/viewBatch/" + check(row)}
              target="_blank"
              sx={{
                fontSize: "14px",
                textTransform: "capitalize",
              }}
            >
              Görüntüle
            </Button>
          ) : (
            <></>
          )}
        </>
      );
    },
  },
];

export const authCertificatesConfig = ({ check, onClick, handleShowModal }) => [
  {
    name: "Firma Adı",
    selector: "firmaAdi",
  },
  {
    name: "Firma Unvanı",
    selector: "firmaUnvani",
  },
  {
    name: "Şartname Adı",
    selector: "sartnameAdi",
  },
  {
    name: "Sertifika Tarihi",
    selector: "sertifikaTarihi",
    cell: (row) => {
      return <>{moment(row.sertifikaTarihi).format("DD/MM/YYYY")}</>;
    },
  },
  {
    name: "Vize Geçerlilik Tarihi",
    selector: "vizeGecerlilikTarihi",
    cell: (row) => {
      return <>{moment(row.vizeGecerlilikTarihi).format("DD/MM/YYYY")}</>;
    },
  },
  {
    name: "Blokzinciri Durumu",
    selector: "vizeGecerlilikTarihi",
    cell: (row) => {
      return (
        <>
          {check(row) ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ color: "green", fontSize: "24px" }}> ●</span>Ağda
            </div>
          ) : (
            <>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ color: "red", fontSize: "24px" }}> ●</span>Ağda
                Yok
              </div>
            </>
          )}
        </>
      );
    },
  },
  {
    name: "TYS Durumu",
    selector: "vizeGecerlilikTarihi",
    width: "130px",
    cell: (row) => {
      return (
        <>
          {row?.tysStatus === "BC" ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ color: "red", fontSize: "24px" }}> ●</span>TYS'de
              yok
            </div>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ color: "green", fontSize: "24px" }}> ●</span>TYS'de
              var
            </div>
          )}
        </>
      );
    },
  },
  {
    name: "İşlemler",
    selector: "date_joined",
    cell: (row) => {
      return (
        <Box display={"flex"} gap={"8px"} alignItems={"center"}>
          {check(row) ? (
            <>
              <Settings
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  handleShowModal({ ...row, certificateId: check(row) });
                }}
              />
              <Button
                variant="contained"
                href={"/viewBatch/" + check(row)}
                target="_blank"
                sx={{
                  fontSize: "14px",
                  textTransform: "capitalize",
                }}
              >
                Görüntüle
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              onClick={() => onClick(row)}
              sx={{
                fontSize: "14px",
                textTransform: "capitalize",
              }}
            >
              Ağa At
            </Button>
          )}
        </Box>
      );
    },
  },
];

export const certificatesBcConfig = ({ onClick }) => [
  {
    name: "Firma Adı",
    selector: "firmaAdi",
    cell: (row) => {
      return <>{row._company[1]}</>;
    },
  },
  {
    name: "Firma Unvanı",
    selector: "firmaUnvani",
    cell: (row) => {
      return <>{row._company[2]}</>;
    },
  },
  {
    name: "Şartname Adı",
    selector: "sartnameAdi",
    cell: (row) => {
      return <>{row._specification[1]}</>;
    },
  },
  {
    name: "Sertifika Tarihi",
    selector: "_createdDate",
    cell: (row) => {
      return <>{moment(row._createdDate).format("DD/MM/YYYY")}</>;
    },
  },
  {
    name: "Vize Geçerlilik Tarihi",
    selector: "_expiredDate",
    cell: (row) => {
      return <>{moment(row._expiredDate).format("DD/MM/YYYY")}</>;
    },
  },
  {
    name: "İşlemler",
    selector: "date_joined",
    cell: (row) => {
      return (
        <Button
          variant="contained"
          onClick={() => onClick(row._certificate_id)}
        >
          Görüntüle
        </Button>
      );
    },
  },
];

export const certificatesPublicConfig = ({ auth, check }) => [
  {
    name: "Firma Adı",
    selector: "firmaAdi",
    cell: (row) => {
      return <>{row._company[1]}</>;
    },
  },
  {
    name: "Firma Unvanı",
    selector: "firmaUnvani",
    cell: (row) => {
      return <>{row._company[2]}</>;
    },
  },
  {
    name: "Şartname Adı",
    selector: "sartnameAdi",
    cell: (row) => {
      return <>{row._specification[1]}</>;
    },
  },
  {
    name: "Sertifika Tarihi",
    selector: "sertifikaTarihi",
    cell: (row) => {
      return <>{moment(row._createdDate).format("DD/MM/YYYY")}</>;
    },
  },
  {
    name: "Vize Geçerlilik Tarihi",
    selector: "vizeGecerlilikTarihi",
    cell: (row) => {
      return <>{moment(row._expiredDate).format("DD/MM/YYYY")}</>;
    },
  },

  {
    name: auth ? "İşlemler" : "",
    selector: "date_joined",
    cell: (row) => {
      return (
        <>
          {auth ? (
            <Button
              variant="contained"
              href={"/viewBatch/" + row._certificate_id}
              target="_blank"
              sx={{
                fontSize: "14px",
                textTransform: "capitalize",
              }}
            >
              Görüntüle
            </Button>
          ) : (
            <></>
          )}
        </>
      );
    },
  },
];
