import {
  AppBar,
  Box,
  Button,
  IconButton,
  InputBase,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/logo.png";
import SearchIcon from "@mui/icons-material/Search";
import Tubu from "@tubu/tubuio-sdk-node/lib/Tubu";
import { apiCall, trimBcData } from "../../../utils/util";
import GTable from "../../../components/GTable/GTable";
import {
  certificatesConfig,
  certificatesPublicConfig,
} from "../../../constants/columnsConfigurations";
const TYS_API_URL = process.env.REACT_APP_TYS_API_URL;

const AdvancedSearch = () => {
  const [value, setValue] = useState("");
  const app = new Tubu("aed9548e-9130-4535-8808-05626caf786b");
  const contract = app.contract("fbf994a2b2e94c0b");

  const [bcData, setBcData] = React.useState([]);

  const [auth, setAuth] = React.useState(false);
  const [initialData, setInitialData] = React.useState([]);
  const getBlockchainData = () => {
    contract
      .event("CreateCertificate")
      .then((result) => {
        const uniqueData = Array.from(
          result.data
            .reduce((map, obj) => {
              map.set(obj.returnValues._certificate_id, obj);
              return map;
            }, new Map())
            .values()
        );
        let filteredData = uniqueData.filter((data) => {
          return data.returnValues._status == "ACTIVE";
        });
        setBcData(trimBcData(filteredData));
        setInitialData(trimBcData(filteredData));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkDataInBlockchain = (data) => {
    if (bcData) {
      for (const item of bcData) {
        if (
          item._company[1] === data.firmaAdi &&
          item._specification[1] === data.sartnameAdi &&
          item._createdDate === data.sertifikaTarihi &&
          item._expiredDate === data.vizeGecerlilikTarihi
        ) {
          return item._certificate_id;
        }
      }
      return false;
    }
  };

  useEffect(() => {
    getBlockchainData();
  }, []);

  const searchFilterByName = (array, searchString) => {
    if (searchString == "") {
      return array;
    }
    return array.filter(
      (row) =>
        row._company[1]
          .toLocaleLowerCase("tr")
          .includes(searchString.toLocaleLowerCase("tr")) ||
        row._company[2]
          .toLocaleLowerCase("tr")
          .includes(searchString.toLocaleLowerCase("tr")) ||
        row._specification[1]
          .toLocaleLowerCase("tr")
          .includes(searchString.toLocaleLowerCase("tr"))
    );
  };

  const certificatesColumns = certificatesPublicConfig({
    auth,
    check: checkDataInBlockchain,
  });

  const filter = () => {
    let searchFilteredArr = searchFilterByName(initialData, value);
    //let paginatedArr = paginate(searchFilteredArr, rowsPerPage, page);
    setBcData(searchFilteredArr);
    return searchFilteredArr;
  };

  useEffect(() => {
    filter(value);
  }, [value]);

  const navigate = useNavigate();

  const queryParameters = new URLSearchParams(window.location.search);
  const email = queryParameters.get("u");
  const password = queryParameters.get("p");

  useEffect(() => {
    if (email && password) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: email, password: password }),
      };
      fetch(TYS_API_URL + "TLogin", requestOptions)
        .then((response) => response.json())
        .then((data) => setAuth(data));
    }
  }, [email, password]);

  return (
    <>
      <AppBar position="static" color="default" elevation={0}>
        <Toolbar sx={{ flexWrap: "wrap" }}>
          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            <img
              onClick={() => navigate("/")}
              src={logo}
              width="160"
              style={{
                cursor: "pointer",
                display: {
                  xs: "flex",
                  md: "none",
                  marginTop: "-20px",
                },
              }}
            />
          </Box>
          <Button
            variant="text"
            sx={{ my: 1, mx: 1.5, color: "black " }}
            onClick={() => navigate("/signin")}
          >
            SİSTEME GİRİŞ
          </Button>
        </Toolbar>
      </AppBar>
      <Box p={4}>
        <Typography variant="h5">Gelişmiş Arama</Typography>
        <Box bgcolor={"#efefef"} borderRadius={4} mt={2} maxWidth={"500px"}>
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Sertifika ara"
            inputProps={{ "aria-label": "search google maps" }}
            onChange={(e) => setValue(e.target.value)}
          />
        </Box>
        <Box mt={2}>
          {bcData && (
            <GTable
              columns={certificatesColumns}
              listedData={bcData}
              count={bcData?.length}
              padding={"16px"}
            ></GTable>
          )}
        </Box>
      </Box>
    </>
  );
};

export default AdvancedSearch;
