import * as services from "../actions";

export const getCompaniesServiceRequest = (reqParams) => ({
  type: services.GET_COMPANIES_SERVICE_REQUEST,
  payload: reqParams,
});

export const getCompaniesServiceSucceed = (reqParams) => ({
  type: services.GET_COMPANIES_SERVICE_SUCCEED,
  payload: reqParams,
});

export const getCompaniesServiceFailed = () => ({
  type: services.GET_COMPANIES_SERVICE_FAILED,
});
